import React, { memo } from 'react';
import PropTypes from 'prop-types';

const IndexPage = ({data}) => {
  return (
    <>
      <div>
      </div>
    </>
  )
}

IndexPage.propTypes = {
  data: PropTypes.any
}

IndexPage.defaultProps = {
  data: {}
}

export default memo(IndexPage);
